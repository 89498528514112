import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon'

import Button from '../Button'
import { transformAlias } from '../../utils'
import RenderIf from '../RenderIf'

export interface HeroData {
  field_cta?: {
    title: string
    uri: string
    uri_alias: string
  }
  relationships: {
    field_image: {
      thumbnail: {
        alt: string
      }
      gatsbyImageData: any
      relationships: {
        field_media_image: {
          uri: {
            value: string
          }
        }
      }
    }
  }
  field_subtitle: string
  field_title: string
}

interface HeroProps {
  hero: HeroData
}

const Hero = ({ hero }: HeroProps) => (
  <section className="md:px-0 md:mb-12">
    <div>
      <div className="relative">
        <div className="max-w-7xl mx-auto">
          <div className="relative md:shadow-xl rounded-2xl">
            <div className="md:absolute inset-0">
              <GatsbyImage
                className="h-full w-full object-cover rounded-2xl"
                image={hero?.relationships?.field_image?.gatsbyImageData}
                alt={hero?.relationships?.field_image?.thumbnail?.alt}
                loading="lazy"
              />
            </div>
            <div className="md:relative md:top-40 md:px-4 md:py-24 lg:px-8">
              <RenderIf condition={!!hero?.field_cta}>
                <Link to={hero?.field_cta?.uri_alias ?? transformAlias(hero?.field_cta?.uri)}>
                  <Button
                    className="rounded-full mt-4 md:mt-0"
                    hoverBackgroundColor="bg-sdusd-orange-300"
                    focusBackgroundColor="bg-sdusd-orange-300"
                    paddingY="py-3"
                    paddingX="px-4"
                    backgroundColor="bg-sdusd-orange-200"
                  >
                    <span className="text-sm md:text-base">{hero?.field_cta?.title}</span>
                    <ChevronRightIcon className="ml-2 w-5 h-5 text-white" aria-hidden="true" />
                  </Button>
                </Link>
              </RenderIf>
              <div className="bg-gray-100 max-w-xl rounded-2xl mt-4 py-3 px-4 md:mt-6">
                <RenderIf condition={!!hero?.field_title}>
                  <h1 className="text-3xl md:text-4xl font-display font-extrabold tracking-tight">
                    <span className="block text-sdusd-orange-100">{hero?.field_title}</span>
                  </h1>
                </RenderIf>
                <RenderIf condition={!!hero?.field_subtitle}>
                  <p className="mt-1 max-w-lg text-base md:text-lg sm:max-w-3xl">
                    {hero?.field_subtitle}
                  </p>
                </RenderIf>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Hero
