import React from 'react'
import { graphql } from 'gatsby'
import Hero from '../../Hero'
import { Paragraph__Header } from '../../../types/generated'

export const ParagraphHeaderFields = graphql`
  fragment ParagraphHeaderFields on paragraph__header {
    __typename
    id
    field_title
    field_subtitle
    relationships {
      field_image {
        thumbnail {
          alt
        }
        gatsbyImageData(width: 1200, aspectRatio: 2)
        relationships {
          field_media_image {
            uri {
              value
            }
          }
        }
      }
    }
  }
`

const ParagraphHeader = (props: Paragraph__Header) => {
  // @todo: REMOVE ANY HACK!
  const propsAny: any = props

  return (
    <section>
      <div className="max-w-7xl mx-auto">
        <Hero hero={propsAny} />
      </div>
    </section>
  )
}

export default ParagraphHeader
